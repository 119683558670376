var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4", attrs: { height: "300" } },
        [
          _c(
            "v-card-text",
            [
              _c("h4", { staticClass: "mb-2" }, [
                _vm._v("Blank Page Content Heading")
              ]),
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  attrs: {
                    to: { path: "/messages/view", query: { status: "sent" } }
                  }
                },
                [_vm._v("View Sent Messages")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <psi-page-title :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></psi-page-title>
        <!-- Content always starts with a v-card  
        -->
        <v-card class="mt-4" height="300">
            <v-card-text>
                <h4 class="mb-2">Blank Page Content Heading</h4>
                <v-divider></v-divider>
                <v-btn :to="{ path: '/messages/view', query: {status: 'sent'}}">View Sent Messages</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "blank-page",
    data() {
        return {
            page: {
                title: "Blank Page",
                icon: "mdi-text-box",
            },
            breadcrumbs: [
                {
                    text: "Pages",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Blank Page",
                    disabled: true,
                },
            ],
        };
    },
    mounted() {},
};
</script>

<style scoped>
</style>